export const apiEndpoints = {
  assets: (() => {
    const baseUrl = '/assets';
    return {
      file: (name: string) => `${baseUrl}/${name}`,
    };
  })(),
  upload: (() => {
    const baseUrl = '/v1/upload';
    return {
      baseUrl,
    };
  })(),
  tat: (() => {
    const baseUrl = '/v1/tat';
    return {
      baseUrl,
      job: (id: number) => `${baseUrl}/${id}`,
    };
  })(),
};
