import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import SwaggerUI from 'swagger-ui';

@Component({
  selector: 'app-swagger',
  templateUrl: './swagger.component.html',
  styleUrls: ['./swagger.component.scss']
})
export class SwaggerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // https://github.com/swagger-api/swagger-ui/blob/master/docs/usage/configuration.md

    SwaggerUI({
      spec: {
        "openapi": "3.0.0",
        "info": {
          "version": "0.0.1",
          "title": "Swagger UI Webpack Setup",
          "description": "Demonstrates Swagger UI with Webpack including CSS and OAuth"
        },
        "servers": [
          {
            "url": "https://demo.identityserver.io/api",
            "description": "Identity Server test API"
          }
        ],
        "components": {
          "securitySchemes": {
            "identity_server_auth": {
              "type": "oauth2",
              "flows": {
                "implicit": {
                  "authorizationUrl": "https://demo.identityserver.io/connect/authorize",
                  "scopes": {
                    "api": "api"
                  }
                }
              }
            }
          }
        },
        "security": [
          {
            "identity_server_auth": [
              "api"
            ]
          }
        ],
        "paths": {
          "/test": {
            "get": {
              "summary": "Runs a test request against the Identity Server demo API",
              "responses": {
                "200": {
                  "description": "OK"
                },
                "401": {
                  "description": "Unauthorized"
                }
              }
            }
          }
        }
      },
      dom_id: '#swagger',
    }).initOAuth({
      appName: "Swagger UI Webpack Demo",
      // See https://demo.identityserver.io/ for configuration details.
      clientId: 'implicit'
    });
  }

}
