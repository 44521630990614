import { AngularHomePageComponent } from './routes/angular-home-page/angular-home-page.component';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FooterComponent } from './components/footer/footer.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HeaderComponent } from './components/header/header.component';
import { HomePageComponent } from './routes/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.component';
import { RoutingModule } from './routing.module';
import { SwaggerComponent } from './components/swagger/swagger.component';
import { SwaggerEditorComponent } from './components/swagger-editor/swagger-editor.component';
import { SwaggerUiComponent } from './components/swagger-ui/swagger-ui.component';
import { UnderConstructionComponent } from './routes/under-construction/under-construction.component';

@NgModule({
  declarations: [
    AngularHomePageComponent,
    AppComponent,
    FooterComponent,
    GalleryComponent,
    HeaderComponent,
    HomePageComponent,
    PageNotFoundComponent,
    SwaggerComponent,
    SwaggerEditorComponent,
    SwaggerUiComponent,
    UnderConstructionComponent,
  ],
  imports: [BrowserModule, RoutingModule, BrowserAnimationsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
