<div class="under-construction">
  <div class="info">
    <p class="title">Witamy na stronie <b>MEBLO</b>-TEK</p>
    <p class="subtitle">Strona w budowie, zapraszamy wkrótce.</p>
  </div>
  <div class="contact">
    <p>
      <b class="nowrap"> Dariusz Michalski </b> <i> właściciel </i>
      <span class="nowrap">+48 <b> 513 011 747 </b></span>   |   <b class="nowrap"> Katarzyna Michalska </b>
      <i> projektant </i>
      <span class="nowrap">+48 <b> 501 189 808 </b></span>
    </p>
    <p>
      ul. Wiosenna 24   |   96-124 Maków Kolonia   |  
      <a href="mailto:biuro@meblo-tek.pl" class="nowrap">biuro@meblo-tek.pl</a>   |  
      <a href="https://meblo-tek.pl" target="_blank" class="nowrap">www.meblo-tek.pl</a>
    </p>
  </div>
</div>
