import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-angular-home-page',
  templateUrl: './angular-home-page.component.html',
  styleUrls: ['./angular-home-page.component.scss']
})
export class AngularHomePageComponent implements OnInit {
  title = 'meblo-tek.pl';

  constructor() { }

  ngOnInit(): void {
  }

}
