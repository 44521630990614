import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

declare const SwaggerEditorBundle: any;
declare const SwaggerEditorStandalonePreset: any;

@Component({
  selector: 'app-swagger-editor',
  templateUrl: './swagger-editor.component.html',
  styleUrls: ['./swagger-editor.component.scss']
})
export class SwaggerEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const editor = SwaggerEditorBundle({
      dom_id: '#swagger-editor',
      layout: 'StandaloneLayout',
      presets: [
        SwaggerEditorStandalonePreset
      ],
      url: 'http://rackerlabs.github.io/wadl2swagger/openstack/swagger/dbaas.json'
    });
  }

}

// https://github.com/dinohorvat/swagger-editor-angular8/blob/master/README.md
