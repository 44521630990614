import { HomePageComponent } from '@routes/home-page/home-page.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from '@routes/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { UnderConstructionComponent } from '@routes/under-construction/under-construction.component';

const routes: Routes = [
  { path: '', redirectTo: '/uc', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  { path: 'uc', component: UnderConstructionComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: true })],
  exports: [RouterModule],
})
export class RoutingModule {}
